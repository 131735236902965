
import { Action } from "vuex-class";
import { Component, Mixins } from "vue-property-decorator";
import { FormBase, FormError, FormInput, FormSelect, FormDate, FormSubmit, FormTextarea, FormCheckbox, FormYesNo } from "@/components/forms";
import { formatErrors } from "@/utils/formatters";
import { epcTypeOptions } from "@/utils/select-options";
import { HasVgeModal } from "@/mixins/vge/has-vge-modal";
import { EpcType } from "@/store/modules/partner.store";
import { DocumentType } from "@/store/modules/document.store";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        FormBase,
        FormInput,
        FormDate,
        FormTextarea,
        FormSelect,
        FormError,
        FormCheckbox,
        FormYesNo,
        FormSubmit,
    },
})
export default class AddVgeEpcModal extends Mixins(HasVgeModal) {
    @Action("vastgoedexperts/createEpc") createEpc!: (payload: any) => Promise<void>;

    EpcType = EpcType;

    epcTypeOptions = epcTypeOptions;

    modalType = "show-vge-add-epc";

    sqMetersOptions = [
        { label: "501m² t.e.m. 750m²", value: 0 },
        { label: "751m² t.e.m. 1000m²", value: 1 },
        { label: "1001m² t.e.m. 1250m²", value: 2 },
        { label: "1251m² t.e.m. 1500m²", value: 3 },
    ];

    documents = [DocumentType.vgeEpcDocumentationIsolation, DocumentType.vgeEpcDocumentationTechnics, DocumentType.vgeEpcDocumentationConstruction_plans, DocumentType.vgeEpcDocumentationOther, DocumentType.vgeEpcDocumentationPhotos];

    async submit(form: FormClass) {
        try {
            this.sanitizePayload();

            await this.createEpc(this.payload);

            this.handleClose();

            if (this.callback && this.partner) {
                this.callback(this.partner);
            }

            this.$toast.open({ message: "Keuring aangevraagd", type: "success" });
        } catch (e) {
            this.errorResponse = formatErrors(e);
        } finally {
            form.reset();
        }
    }
}
